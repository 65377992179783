<template>
  <div search-articles>
    <form action="/search" method="GET" role="search" @submit.prevent="onSubmit($event)">
      <input type="search" name="q" role="searchbox" placeholder="Search here" aria-description="Search here" v-model="search" />
    </form>
  </div>
</template>
<style src="~/assets/scss/section/search-articles.scss" lang="scss"></style>
<script setup>
const search = defineModel();

const onSubmit = (event) => {
  return;
};
</script>
